<template>
    <!-- 新建banner页面 -->
    <div class="positionT0L0">
        <Information :name="information.nickName" :phone="information.userName" :data="information.createTime"></Information>
        <div class="head-box">
            <div class="table-operator">
                <span class="item">
                    <a-range-picker v-model="dataVal" :placeholder="['最近播放开始日期', '最近播放结束日期']" @change="onChangeDate" style="width: 210px;"/>
                </span>
                <span class="item">
                    <a-select ref="select" v-model="form.categoryNo" allowClear placeholder="书籍分类" style="width: 210px;">
                        <a-select-option v-for="item in cateList" :value="item.cateNo" :key="item.cateNo">{{ item.cateName }}</a-select-option>
                    </a-select>
                </span>
                
                <span class="item">
                    <a-button class="margin_right10" type="primary" @click="queryClick">搜索</a-button>
                    <a-button class="margin_right10" type="primary" @click="resetClick">重置</a-button>
                    <!--<a-button type="primary" @click="exportClick">导出</a-button>-->
                </span>
            </div>
        </div>
        <div class="contant-box">
            <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.courseNo" :loading="tableLoading" :pagination="false">
                <template slot="action" slot-scope="text, record" v-if="isShowDetail">
                    <a @click="details(record)">详情</a>
                </template>
            </a-table>
        </div>
        
        <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
        
        <!--    导出弹框    -->
        <a-modal v-model="visibleShow" title="导出">
            <template #footer>
                <div style="display: flex;justify-content: flex-end">
                    <a-button @click="visibleShow = false" class="margin_right20">取消</a-button>
                    <download-excel
                        :data="exportList"
                        :fields="tableDataName"
                        name="用户课程播放详情.xls">
                        <a-button type="primary" @click="visibleShow = false">确定</a-button>
                    </download-excel>
                </div>
            </template>
            <p>
                <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
                <span style="margin-left: 10px">确定要导出此数据吗？</span>
            </p>
        </a-modal>
        
        <!--    详情弹窗    -->
        <a-drawer title="详情" size="large" :visible="visible" @close="visible = false" :destroyOnClose="true">
            <a-table ref="table2" :columns="columns2" :data-source="tableList2" :row-key="record => record.courseNo" :loading="tableLoading2" :pagination="false"></a-table>
<!--            <MyPagination :count="count2" :pageNo="pageNo2" @showSizeChangeFn="showSizeChangeFn2" v-show="!tableLoading2"/>-->
            <div  style="display: flex;justify-content: flex-end;margin-top: 20px">
                <a-pagination :current="pageNo2" :pageSize="pageSize2" :total="count2" @change="showSizeChangeFn2" show-less-items />
            </div>
        </a-drawer>
        
        <footer-tool-bar :collapsed="sideCollapsed">
            <a-button @click="$router.go(-1)">返回</a-button>
        </footer-tool-bar>
    </div>
</template>

<script>
import { baseMixin } from "@/store/app-mixin";
import FooterToolBar from "@/components/FooterToolbar";
import MyPagination from "@/components/pagination/MyPagination";
import Information from "@/components/information/information";
import {GetSubCateOptionsByType, UserCoursePlayDetail, UserLessonsPoints, UserPlayList} from "@/request/api/userManage";
import {codeFn} from "@/utils/tools";

export default {
    mixins: [baseMixin],
    components: { FooterToolBar,MyPagination,Information },
    created() {
        if (codeFn("/adminv2/playvideo/userCoursePlayDetail/book/detail")) this.isShowDetail = true;
        
        this.objs = JSON.parse(this.$route.params.id)
        this.getTagList()
        this.cateTypeList()
    },
    data() {
        return {
            isShowDetail:false,
            
            objs:{},
            form:{
                categoryNo:undefined,
                startTime:'',
                endTime:'',
            },
            tableLoading:false,
            visibleShow:false,
            visible:false,
            columns: [
                {
                    title: "书籍名称",
                    dataIndex: "courseName",
                    key: "courseName",
                },
                {
                    title: "分类",
                    dataIndex: "categoryName",
                    key: "categoryName",
                },
                {
                    title: "总学习时长",
                    dataIndex: "totalStudy",
                    key: "totalStudy",
                },
                {
                    title: "首次播放时间",
                    dataIndex: "firstStudy",
                    key: "firstStudy",
                },
                {
                    title: "最近一次播放时间",
                    dataIndex: "lastStudy",
                    key: "lastStudy",
                },
                {
                    title: "书籍小节",
                    dataIndex: "lastLessonName",
                    key: "lastLessonName",
                },
                {
                    title: "操作",
                    dataIndex: "action",
                    key: "action",
                    width: "80px",
                    scopedSlots: {customRender: "action"},
                },
            ],
            tableList: [],
            dataVal:[], // 时间搜索
            cateList:[], // 根据类型获取二级分类列表
            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0,// 列表的总条数
            information:{},
            
            //导出
            exportList:[],
            tableDataName: {
                "书籍名称": "courseName",
                "分类": "categoryName",
                "总学习时长": "totalStudy",
                "首次播放时间": "firstStudy",
                "最近一次播放时间": "lastStudy",
                "书籍小节": "lastLessonName",
            },
            
            // 详情
            columns2:[
                {
                    title: "课讲名称",
                    dataIndex: "lessonsName",
                    key: "lessonsName",
                },
                {
                    title: "总播放时长",
                    dataIndex: "playTimes",
                    key: "playTimes",
                },
                {
                    title: "最近一次播放时间",
                    dataIndex: "lastStudy",
                    key: "lastStudy",
                },
                {
                    title: "首次播放时间",
                    dataIndex: "firstStudy",
                    key: "firstStudy",
                },
            ],
            tableList2:[],
            tableLoading2:false,
            pageNo2:1,
            pageSize2: 20,
            count2:0,
    
            courseNo:'',
        };
    },
    methods: {
        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList()
        },
        
        // 搜索 - 修改时间
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.startTime = dateString[0];
                this.form.endTime = dateString[1];
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },
        
        // 点击查询
        queryClick(){
            this.tableList = []
            this.pageNo = 1
            this.getTagList()
        },
        
        // 点击重置
        resetClick(){
            this.form.categoryNo = undefined
            this.form.startTime = ''
            this.form.endTime = ''
            this.dataVal = []
            this.getTagList()
        },
        
        // 点击导出
        exportClick(){
            UserCoursePlayDetail({
                userNo: this.objs.userNo,
                cateType: this.objs.cateType,
                categoryNo: this.form.categoryNo == undefined ? '' : this.form.categoryNo,
                startTime: this.form.startTime,
                endTime: this.form.endTime,
                pageNo: this.pageNo,
                pageSize: 0
            }).then((res) => {
                if (res.code === 200) {
                    this.exportList = res.data.list
                    if(this.exportList.length>0){
                        this.visibleShow = true
                    }else{
                        this.$message.warning('暂无导出数据')
                    }
                }
            });
        },
        
        // 阅读分类数据
        cateTypeList() {
            GetSubCateOptionsByType({
                cateType: this.objs.cateType,
            }).then((res) => {
                if (res.code === 200) {
                    this.cateList = res.data;
                }
            });
        },
        
        // 列表数据
        getTagList() {
            this.tableLoading = true;
            UserCoursePlayDetail({
                userNo: this.objs.userNo,
                cateType: this.objs.cateType,
                categoryNo: this.form.categoryNo == undefined ? '' : this.form.categoryNo,
                startTime: this.form.startTime,
                endTime: this.form.endTime,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                    this.information = res.data.userData
                }
                this.tableLoading = false;
            });
        },
        
        // 详情分页
        showSizeChangeFn2(current, pageSize){
            this.pageNo2 = current;
            this.pageSize2 = pageSize;
            this.detailsInit()
        },
        
        // 点击详情
        details(record){
            this.courseNo = record.courseNo
            this.detailsInit()
        },
        
        // 详情接口
        detailsInit(){
            this.tableLoading2 = true
            UserLessonsPoints({
                userNo: this.objs.userNo,
                courseNo: this.courseNo,
                pageNo: this.pageNo2,
                pageSize: this.pageSize2
            }).then((res) => {
                if (res.code === 200) {
                    this.visible = true
                    this.tableList2 = res.data.list;
                    this.count2 = res.data.count;
                }
                this.tableLoading2 = false
            });
        }
    },
};
</script>

<style lang="less" scoped>
.head-box{
    margin-top: 15px;
    .table-operator{
        display: flex;
        .item{
            margin-right: 20px;
        }
    }
}
/deep/ .ant-input{
    min-height: 32px !important;
}
/deep/ .ant-select-selection--single{
    min-height: 32px !important;
}
/deep/.ant-drawer-content-wrapper{
    width: 60% !important;
}
</style>
